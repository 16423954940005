<script lang="ts" setup>
// import { Size } from '~/types/custom'

import type { ButtonHTMLAttributes } from 'vue'

const { className, binding } = defineProps<{
  className?: string
  binding?: ButtonHTMLAttributes
}>()
const emits = defineEmits<{ (e: 'onClick'): void }>()
</script>

<template lang="pug">
.hex-button(
  @click="emits('onClick')"
)
  button(:class="className" v-bind="binding || {}")
    slot
</template>

<style lang="sass" scoped>
.hex-button
  @apply w-fit relative px-2 py-1 cursor-pointer
  button
    @apply clip-hex px-8 py-2
</style>
